import { permissions } from '@mpk/shared/domain';

/**
 * Medical shop can be used
 */
enum MedicalShopPermissionsEnum {
  None = 0,

  /**
   * Medical shop can be used
   */
  UseMedicalShop = 1,

  /**
   * Industryarticles can be used within the medical shop.
   */
  UseIndustryArticlesInMedicalShop = 2 | UseMedicalShop,
}

export const MedicalShopPermissions = permissions(MedicalShopPermissionsEnum, {
  featureId: 'MedicalShop',
});
